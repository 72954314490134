// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-area-ingenieria-diseno-e-ingenieria-jsx": () => import("./../../../src/pages/area/ingenieria/diseno-e-ingenieria.jsx" /* webpackChunkName: "component---src-pages-area-ingenieria-diseno-e-ingenieria-jsx" */),
  "component---src-pages-area-ingenieria-fabricacion-de-montaje-de-estructuras-metalicas-jsx": () => import("./../../../src/pages/area/ingenieria/fabricacion-de-montaje-de-estructuras-metalicas.jsx" /* webpackChunkName: "component---src-pages-area-ingenieria-fabricacion-de-montaje-de-estructuras-metalicas-jsx" */),
  "component---src-pages-area-ingenieria-fabricacion-de-spools-y-piezas-especiales-para-tuberia-jsx": () => import("./../../../src/pages/area/ingenieria/fabricacion-de-spools-y-piezas-especiales-para-tuberia.jsx" /* webpackChunkName: "component---src-pages-area-ingenieria-fabricacion-de-spools-y-piezas-especiales-para-tuberia-jsx" */),
  "component---src-pages-area-ingenieria-jsx": () => import("./../../../src/pages/area/ingenieria.jsx" /* webpackChunkName: "component---src-pages-area-ingenieria-jsx" */),
  "component---src-pages-area-ingenieria-reparacion-de-componentes-jsx": () => import("./../../../src/pages/area/ingenieria/reparacion-de-componentes.jsx" /* webpackChunkName: "component---src-pages-area-ingenieria-reparacion-de-componentes-jsx" */),
  "component---src-pages-area-servicios-construccion-y-operacion-de-plantas-solares-fotovoltaicas-jsx": () => import("./../../../src/pages/area/servicios/construccion-y-operacion-de-plantas-solares-fotovoltaicas.jsx" /* webpackChunkName: "component---src-pages-area-servicios-construccion-y-operacion-de-plantas-solares-fotovoltaicas-jsx" */),
  "component---src-pages-area-servicios-entrenamiento-y-certificacion-ndt-jsx": () => import("./../../../src/pages/area/servicios/entrenamiento-y-certificacion-ndt.jsx" /* webpackChunkName: "component---src-pages-area-servicios-entrenamiento-y-certificacion-ndt-jsx" */),
  "component---src-pages-area-servicios-jsx": () => import("./../../../src/pages/area/servicios.jsx" /* webpackChunkName: "component---src-pages-area-servicios-jsx" */),
  "component---src-pages-area-servicios-servicio-de-alineamiento-de-laser-de-ejes-jsx": () => import("./../../../src/pages/area/servicios/servicio-de-alineamiento-de-laser-de-ejes.jsx" /* webpackChunkName: "component---src-pages-area-servicios-servicio-de-alineamiento-de-laser-de-ejes-jsx" */),
  "component---src-pages-area-servicios-servicio-de-ensayos-no-destructivos-jsx": () => import("./../../../src/pages/area/servicios/servicio-de-ensayos-no-destructivos.jsx" /* webpackChunkName: "component---src-pages-area-servicios-servicio-de-ensayos-no-destructivos-jsx" */),
  "component---src-pages-area-servicios-servicios-de-outsourcing-jsx": () => import("./../../../src/pages/area/servicios/servicios-de-outsourcing.jsx" /* webpackChunkName: "component---src-pages-area-servicios-servicios-de-outsourcing-jsx" */),
  "component---src-pages-areas-jsx": () => import("./../../../src/pages/areas.jsx" /* webpackChunkName: "component---src-pages-areas-jsx" */),
  "component---src-pages-clientes-jsx": () => import("./../../../src/pages/clientes.jsx" /* webpackChunkName: "component---src-pages-clientes-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-nosotros-jsx": () => import("./../../../src/pages/nosotros.jsx" /* webpackChunkName: "component---src-pages-nosotros-jsx" */),
  "component---src-pages-proyecto-grupo-falabella-jsx": () => import("./../../../src/pages/proyecto/grupo-falabella.jsx" /* webpackChunkName: "component---src-pages-proyecto-grupo-falabella-jsx" */),
  "component---src-pages-proyecto-mantenimiento-mina-cerro-verde-jsx": () => import("./../../../src/pages/proyecto/mantenimiento-mina-cerro-verde.jsx" /* webpackChunkName: "component---src-pages-proyecto-mantenimiento-mina-cerro-verde-jsx" */),
  "component---src-pages-proyecto-mantenimiento-mina-toquepala-jsx": () => import("./../../../src/pages/proyecto/mantenimiento-mina-toquepala.jsx" /* webpackChunkName: "component---src-pages-proyecto-mantenimiento-mina-toquepala-jsx" */),
  "component---src-pages-proyecto-marcobre-jsx": () => import("./../../../src/pages/proyecto/marcobre.jsx" /* webpackChunkName: "component---src-pages-proyecto-marcobre-jsx" */),
  "component---src-pages-proyecto-proyecto-fotovoltaico-clemesi-moquegua-jsx": () => import("./../../../src/pages/proyecto/proyecto-fotovoltaico-clemesi-moquegua.jsx" /* webpackChunkName: "component---src-pages-proyecto-proyecto-fotovoltaico-clemesi-moquegua-jsx" */),
  "component---src-pages-proyecto-proyecto-fotovoltaico-rubi-moquegua-jsx": () => import("./../../../src/pages/proyecto/proyecto-fotovoltaico-rubi-moquegua.jsx" /* webpackChunkName: "component---src-pages-proyecto-proyecto-fotovoltaico-rubi-moquegua-jsx" */),
  "component---src-pages-proyecto-proyecto-quellaveco-norte-jsx": () => import("./../../../src/pages/proyecto/proyecto-quellaveco-norte.jsx" /* webpackChunkName: "component---src-pages-proyecto-proyecto-quellaveco-norte-jsx" */),
  "component---src-pages-proyecto-proyecto-quellaveco-tuneles-jsx": () => import("./../../../src/pages/proyecto/proyecto-quellaveco-tuneles.jsx" /* webpackChunkName: "component---src-pages-proyecto-proyecto-quellaveco-tuneles-jsx" */),
  "component---src-pages-proyecto-sociedad-minera-cerro-verde-jsx": () => import("./../../../src/pages/proyecto/sociedad-minera-cerro-verde.jsx" /* webpackChunkName: "component---src-pages-proyecto-sociedad-minera-cerro-verde-jsx" */),
  "component---src-pages-proyectos-jsx": () => import("./../../../src/pages/proyectos.jsx" /* webpackChunkName: "component---src-pages-proyectos-jsx" */)
}

